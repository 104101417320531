const COLLECTOR_PATH = 'api/v1/track';
const endpointsCollector = {
    DEV: {
        URL: 'https://collector.pulse-internal-dev.schibsted.io',
        PATH: COLLECTOR_PATH,
    },
    PRE: {
        URL: 'https://collector.pulse-internal-pre.schibsted.io',
        PATH: COLLECTOR_PATH,
    },
    PRO: {
        URL: 'https://collector.schibsted.io',
        PATH: COLLECTOR_PATH,
    },
};
const endpointsCis = {
    DEV: {
        URL: 'https://cis.pulse-internal-dev.schibsted.io',
    },
    PRE: {
        URL: 'https://cis.pulse-internal-pre.schibsted.io',
    },
    PRO: {
        URL: 'https://cis.schibsted.com',
    },
};
const baseConfig = {
    cisEnabled: true,
    eventDebounce: 200,
    environment: 'browser',
    useBeaconWhenAvailable: true,
};
export const configDev = {
    ...baseConfig,
    cisBaseUrl: endpointsCis.DEV.URL,
    collectorBaseUrl: endpointsCollector.DEV.URL,
    collectorPath: endpointsCollector.DEV.PATH,
};
export const configPre = {
    ...baseConfig,
    cisBaseUrl: endpointsCis.PRE.URL,
    collectorBaseUrl: endpointsCollector.PRE.URL,
    collectorPath: endpointsCollector.PRE.PATH,
};
export const configPro = {
    ...baseConfig,
    cisBaseUrl: endpointsCis.PRO.URL,
    collectorBaseUrl: endpointsCollector.PRO.URL,
    collectorPath: endpointsCollector.PRO.PATH,
};
export default configPro;
