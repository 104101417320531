/* eslint-disable @typescript-eslint/ban-ts-comment */
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import * as logger from 'loglevel';
import mergeWith from 'lodash/mergeWith';
import { v4 } from 'uuid';
import * as identity from './identity';
import { events, provider, utils as eventBuilderUtils, } from './builders';
import { configDev, configPre, configPro } from './config';
import defer from './defer';
import { addPageLeaveTracking, removePageLeaveTracking, resetViewedContent, trackActivePageLeave, trackPageLeave, updatePageLeaveEvent, updatePageLeaveTracking, updateViewedContent, } from './leaveTracking';
import send from './network';
import version from './version.json';
import { applyAdvertisingConsent } from './builders/consents';
import { isBrowser } from './WebApi';
const { generateProviderSDRN: providerSdrn } = provider;
const { evaluateAndFlatten } = eventBuilderUtils;
function getEventTrackingMethod(name) {
    // @ts-ignore
    return events[name] || events.trackerEvent;
}
/**
 * Determines if the SDK is running in a Hybrid (WebView) environment.
 *
 * @remarks
 *
 * Historically, we have detected the presence of "nativeJwe" config coming from the native app to flag that setup.
 * Additionally, a config param can be passed to explicitly indicate that the tracker was initialised in a mobile WebView.
 *
 * @param config {SDKConfigInput}
 * @return {boolean}
 */
const isHybrid = (config) => Boolean(config?.isHybrid) || Boolean(config?.nativeJwe);
/**
 * @internal
 */
export const BookmarkKeys = {
    PAGE_VIEW: 'page-view',
};
const getConfigFromDeployStage = (deployStage) => {
    switch (deployStage?.toLowerCase()) {
        case 'dev': {
            return configDev;
        }
        case 'pre': {
            return configPre;
        }
        case 'pro': {
            return configPro;
        }
        default: {
            return configPro;
        }
    }
};
/**
 * @class Tracker
 */
export default class Tracker {
    /**
     * @param {string} providerId (*required*). ID of the site or app. Read the [integration
     * guide](https://docs.schibsted.io/pulse/integration_guide/step2/) to see where your providerId can be
     * registered and retrieved.
     * @param config
     * @param builders
     */
    constructor(providerId, config, builders) {
        logger.setLevel(config?.logLevel ?? logger.levels.ERROR);
        if (!providerId || !(typeof providerId === 'string')) {
            throw new Error('Required parameter `providerId` missing');
        }
        const configByDeployStage = getConfigFromDeployStage(config?.deployStage);
        const includeAdvertising = applyAdvertisingConsent(config?.consents, config?.requireAdvertisingOptIn);
        identity.clearCookieJweIfStale(config?.nativeJwe);
        this.state = merge({}, configByDeployStage, {
            includeAdvertising,
            isHybrid: isHybrid(config),
            pageViewId: v4(),
            providerId,
            trackerId: v4(),
            vendors: ['xandr'],
        }, config);
        // Move to default config?
        this.builders = merge({}, builders, {
            provider: {
                id: providerId,
            },
            tracker: {
                version: version.SDK_VERSION,
            },
        });
        this.bookmark = new Map();
        // Replace with a linked list of Promises?
        this.eventQueue = [];
        this.retryEventQueue = [];
        // This is technically/strictly speaking not necessary to do before the
        // first event which requires an environmentId is sent. This will in most
        // cases be all events on client-instances and doing this immediately will
        // unblock the first event faster.
        // The reason for the timeout is to cover the case of
        // const tracker = new Tracker();
        // tracker.login('user@example.org');
        if (this.state.environment === 'browser') {
            setTimeout(() => {
                identity.cisSync({}, this.builders, this.state).then(() => {
                    void 0;
                });
            }, 10);
            // @ts-ignore
            if (isBrowser && window.navigator?.sendBeacon) {
                const originalUseBeacon = this.state.useBeacon;
                window.addEventListener('pagehide', () => {
                    this.state.useBeacon = true;
                    this._sendImmediately().then(() => {
                        void 0;
                    });
                }, { capture: true });
                window.addEventListener('pageshow', () => {
                    this.state.useBeacon = originalUseBeacon;
                });
            }
        }
    }
    /**
     * Add event to the tracker event queue
     *
     * The input can be objects, arrays, primitives or promises. The
     * order of calls to the method is not guaranteed to be sent in the same
     * sequence.
     *
     * ```
     * tracker.track('trackerEvent', {
     * actor: new Promise(resolve => setTimeout(() => resolve('person@example.org'), 2000))
     * });
     * tracker.track('trackerEvent', { type: 'Engagement '});
     *
     * ```
     *
     * The second call to `track` will be added to the event queue before the
     * first since all input to events are resolved before being added to the
     * internal queue. Setting the future value in the SDK state will block all
     * events and events will be added to the queue in order.
     *
     * ```
     * tracker.update({
     * actor: new Promise(resolve =>
     * setTimeout(() => resolve('person@example.org'), 2000)) })
     * tracker.track('trackerEvent', { type: 'View' });
     * tracker.track('trackerEvent', { type: 'Engagement' });
     * ```
     *
     * Both of these events will be blocked for two seconds and executed in order.
     *
     * @param {string} eventType. See [spt-tracking/pulse-event-builders]() for
     * available event types
     * @param {object} input. input input to the given event. The given input and the
     * persisted input in the tracker will be merged before passing it to the event
     * function.
     * @param {TrackOptions} options
     * @category Tracker
     */
    track(eventType, input, options) {
        const currentBuilders = { ...this.builders };
        const _track = async (trackResponse) => {
            return this.evaluateEvent(eventType, input, currentBuilders, options).then(async (event) => {
                if (options?.bookmarkKey) {
                    this.bookmark.set(options.bookmarkKey, event);
                }
                // if we have an alternative handler defined, send events there
                // instead of pushing them onto the queue.
                if (this.state.altEventHandler) {
                    const handler = this.state.altEventHandler;
                    handler(JSON.stringify(event));
                    return Promise.resolve(trackResponse);
                }
                const queuedEvent = this._push(event, options).then(() => void 0);
                await this.send();
                return queuedEvent.then((result) => result);
            });
        };
        return identity.cisSync(input, currentBuilders, { ...this.state }).then(_track, _track);
    }
    /**
     * Evaluate all input given to the sdk/this method and pass it through the
     * appropriate event formatter.
     * @category Tracker
     */
    async evaluateEvent(eventType, input, builders = this.builders, options) {
        const eventBuilder = getEventTrackingMethod(eventType);
        // @ts-ignore
        return this.evaluateEventInputs(eventType, input, builders, options).then((evaluatedInput) => 
        // @ts-ignore
        evaluateAndFlatten(eventBuilder(evaluatedInput, this.state)));
    }
    /**
     *
     * @param eventType
     * @param eventInput
     * @param builders
     * @param options
     * @category Tracker
     */
    async evaluateEventInputs(eventType, eventInput, builders = this.builders, options) {
        return Promise.all([evaluateAndFlatten(builders), evaluateAndFlatten(eventInput)]).then((builders) => {
            let eventOutput = builders.reduce((acc, p) => merge(acc, p), {});
            eventOutput = this.applyEventTypeDefaults(eventOutput, eventType);
            eventOutput = this.applyBookmarkedEvent(eventOutput, options?.applyBookmarkKey);
            eventOutput = this.applyConsents(eventOutput);
            return eventOutput;
        });
    }
    /**
     * applyEventTypeDefaults
     * @param eventInput
     * @param eventType
     * @private
     */
    applyEventTypeDefaults(eventInput, eventType) {
        const eventDefaults = getEventTrackingMethod(eventType).defaults;
        return merge({}, eventDefaults(), eventInput);
    }
    /**
     * applyBookmarkedEvent
     * @param eventInput
     * @param bookmarkKey
     * @private
     */
    applyBookmarkedEvent(eventInput, bookmarkKey) {
        const eventOutput = Object.assign({}, eventInput);
        if (bookmarkKey) {
            const bookmarkedPageViewEvent = this.bookmark.get(bookmarkKey) ?? {};
            const { ['@id']: _id, ['@type']: _type, creationDate: _creationDate, ...coreEvent } = bookmarkedPageViewEvent;
            return merge({}, eventOutput, coreEvent);
        }
        return eventInput;
    }
    /**
     * applyConsents
     * @param eventInput
     * @private
     */
    applyConsents(eventInput) {
        return merge({}, eventInput, this.evaluateConsents());
    }
    /**
     * Enriches the event with consent data saved in state.
     * @category Consents
     */
    evaluateConsents() {
        const consents = this.state.consents;
        return {
            ...(consents && { consents }),
        };
    }
    /**
     * @deprecated
     * Adds the user's consents (opt-ins) to data processing for specific purposes, e.g. personalization.
     * These consent purposes must only be used if you want to
     * enrich Pulse events with the consent choices made by the current user.
     *
     * *WARNING:* It is critically important that
     * a consent is only added if the user has actually given that consent (opted in).
     * Only an explicit yes from the user means yes for these consents. Declined consent choices (opt-outs) must not be
     * added.
     *
     * Adding a consent purpose to the SDK saves it to the SDK state, and this consent state will be sent with every
     * outgoing event after going through the consents builder in \[@spt-tracking/pulse-event-builders].
     *
     * @param {ConsentedToPurposeInput} purposes (*required*). Consent purposes to be added to every outgoing event.
     * A consent purpose consists of a *category* and an input, in the form of an *id* or a full Purpose object.
     * Category is a textual representation of a consent purpose.
     * Possible values are CMP_ANALYTICS, CMP_MARKETING, CMP_ADVERTISING and CMP_PERSONALISATION. The id is the
     * unique identifier of the consent purpose from the CMP. When the input is an id string, the SDK parses a complete
     * Purpose object for output.
     * @category Consents
     */
    addConsentPurposes(purposes) {
        for (const category in purposes) {
            const consentCategory = category;
            this.addSingleConsentPurpose(consentCategory, purposes[consentCategory]);
        }
    }
    /**
     * @deprecated
     * Adds a single user consent (opt-in) to data processing for a specific purpose, e.g. personalization.
     * See also {@link addConsentPurposes}
     * @param {ConsentCategory} category (*required*). Purpose category to be attached to the events. Allowed values are
     * CMP_ANALYTICS, CMP_MARKETING, CMP_ADVERTISING and CMP_PERSONALISATION
     * @param {ConsentedToPurposeInput} input (*required*). Purpose ID of the allowed category or a complete Purpose object
     * @category Consents
     */
    addSingleConsentPurpose(category, input) {
        if (this.state.consents?.purposes) {
            this.state.consents.purposes[category] = input;
        }
        else {
            this.state.consents = {
                purposes: {
                    [category]: input,
                },
            };
        }
    }
    /**
     * @deprecated
     * Removes consent purposes from the SDK. This means that the SDK will consider that the user has declined these
     * consent purposes and will communicate this downstream.
     * @param {string[]} categories (*required*). Categories of the consent purposes the user has opted out of.
     * @category Consents
     */
    removeConsentPurposes(categories) {
        for (const category of categories) {
            this.removeSingleConsentPurpose(category);
        }
    }
    /**
     * @deprecated
     * Removes a single consent purpose from the SDK. This means that the SDK will consider that the user has declined
     * this consent purpose and will communicate this downstream.
     * @param {string} category (*required*). Category of the consent purposes the user has opted out of.
     * @category Consents
     */
    removeSingleConsentPurpose(category) {
        delete this.state.consents?.purposes[category];
    }
    /**
     * @deprecated
     * Clears consent purposes. This must only be used if the user has opted out of all consent purposes.
     * @category Consents
     */
    clearConsentPurposes() {
        this.replaceConsentPurposes({});
    }
    /**
     * @deprecated
     * Overrides consent purposes in the SDK. This sets the consent purposes in the SDK state to the given object. This
     * consent state will be sent with every outgoing event after going through the consents builder in
     * [@spt-tracking/pulse-event-builders].
     * @param {ConsentedToPurposeInput} purposes (*required*). Consent purposes to be added to every outgoing event.
     * @category Consents
     */
    replaceConsentPurposes(purposes) {
        this.state.consents = {
            purposes,
        };
    }
    /**
     * Gets user consents from the tracker's state.
     * @returns {Consents}
     * @category Consents
     */
    getConsents() {
        return this.evaluateConsents().consents;
    }
    /**
     * Sets user consents to be sent with each event.
     * Given that User's consent for Advertising changed, the next call to get Advertising Identifiers must be forced, and
     * not retrieve values from cache. The flag is passed with the trackers state, and updated to a "non-forcing" state
     * right after, characterising the "force once" behaviour.
     * accessing Advertising Identifiers directly must clear this flag.
     * @param consentsInput
     * @returns {Consents}
     * @category Consents
     */
    setConsents(consentsInput) {
        const currentIncludeAdvertising = this.state.includeAdvertising;
        const updatedIncludeAdvertising = applyAdvertisingConsent(consentsInput, this.state.requireAdvertisingOptIn);
        this.state.includeAdvertising = updatedIncludeAdvertising;
        this.state.consents = consentsInput;
        if (currentIncludeAdvertising !== updatedIncludeAdvertising) {
            identity.requireCisSyncCallForAdvertisingIds();
        }
        return { ...this.state.consents };
    }
    /**
     * Return a new Tracker with the current state
     * @category Tracker
     */
    clone({ trackerId } = {}) {
        return new Tracker(this.state.providerId, {
            ...this.state,
            trackerId: trackerId || v4(),
        }, {
            ...this.builders,
        });
    }
    /**
     * Update the persisted state that is passed to all events
     *
     * Calling
     * ```
     * tracker.update({ actor: 'person@example.org' });
     * tracker.trackPageView();
     * tracker.trackPageView();
     * ```
     *
     * is equivalent of doing
     *
     * ```
     * tracker.trackPageView({ actor: 'person@example.org' });
     * tracker.trackPageView({ actor: 'person@example.org' });
     * ```
     *
     * @param {EventInput} builder data passed to event functions
     *
     * @param {boolean} mergeBuilder the given data will be merged deeply with the
     * existing state. False will result in the previous state being overwritten
     * @category Tracker
     */
    update(builder, mergeBuilder = false) {
        function conflictResolver(objValue, srcValue) {
            if (objValue instanceof Promise || srcValue instanceof Promise) {
                return Promise.all([objValue, srcValue]).then(([obj, src]) => mergeWith({}, obj, src, conflictResolver));
            }
            return undefined;
        }
        if (mergeBuilder) {
            this.builders = mergeWith({}, this.builders, builder, conflictResolver);
        }
        else {
            this.builders = Object.assign({}, this.builders, builder);
        }
    }
    /**
     * Get the actor id (userId) from the current browser. A promise is returned
     * because a call may be made to complete the login process.
     *
     * *Note*: This method will return a Promise that is always rejected on node
     environments.
     @category Identifiers
     */
    async getUserId() {
        return identity.getUserId(this.builders);
    }
    /**
     * Get the includeAdvertising flag, set on initialisation.
     * @private
     * @see constructor
     * @category Identifiers
     */
    getIncludeAdvertising() {
        return identity.getIncludeAdvertising(this.state);
    }
    /**
     * Get the advertisement id (adId) from the current browser. A promise is returned
     * because a CIS call is done to generate a new adId if no cookie is
     * found.
     *
     * *Note*: This method will return a Promise that is always rejected on node
     * environments.
     * @category Identifiers
     */
    async getAdId() {
        return identity.getAdId(this.builders, this.state);
    }
    /**
     * @category Identifiers
     */
    async getActor() {
        return identity.getActor(this.builders);
    }
    /**
     * Get the environment id from the current browser. A promise is returned
     * because a CIS call is done to generate a new environmentId if no cookie is
     * found.
     *
     * *Note*: This method will return a Promise that is always rejected on node
     * environments.
     * @category Identifiers
     */
    async getEnvironmentId() {
        return identity.getEnvironmentId(this.builders, this.state);
    }
    /**
     * Returns the PPID1 value for Xandr integration.
     * This is the "logged in identifier".
     * @category Identifiers
     * @deprecated Use getAdvertisingIdentifiers instead
     */
    async getXandrPPID1() {
        const { xandr } = await identity.getAdvertisingIdentifiers(this.builders, { ...this.state }, [
            identity.AdvertisingVendors.Xandr,
        ]);
        return xandr?.ppId1;
    }
    /**
     * Returns the PPID2 value for Xandr integration.
     * This is the "not logged in identifier".
     * @category Identifiers
     * @deprecated Use getAdvertisingIdentifiers instead
     */
    async getXandrPPID2() {
        const { xandr } = await identity.getAdvertisingIdentifiers(this.builders, { ...this.state }, [
            identity.AdvertisingVendors.Xandr,
        ]);
        return xandr?.ppId2;
    }
    /**
     * Returns the Advertising Identifiers for a Vendor or all Vendors.
     * Advertising Identifiers are "AdId", "PPID1", "PPID2", etc ...
     * @param [vendors]
     * @category Identifiers
     */
    async getAdvertisingIdentifiers(vendors) {
        return identity.getAdvertisingIdentifiers(this.builders, { ...this.state }, vendors);
    }
    /**
     * Returns the UUID currently used as the page view (will be muted by
     * `newPageView` and `trackPageView`).
     * @category Tracker
     */
    getPageViewId() {
        return this.state.pageViewId;
    }
    /**
     * Generate a new page view id. Will be attached to all events until a new
     * id is generated.
     * @category Tracker
     */
    newPageView() {
        this.state.pageViewId = v4();
    }
    /**
     * @internal
     * @param providerId
     */
    _getCollectorPath(providerId) {
        const { collectorBaseUrl, collectorPath, environment } = this.state;
        if (environment === 'node') {
            return `${collectorBaseUrl}/${collectorPath}`;
        }
        const _provider = providerId || this.state.providerId;
        if (typeof _provider === 'string' && _provider.indexOf('sdrn') === 0) {
            return `${collectorBaseUrl}/${collectorPath}/${_provider}`;
        }
        return `${collectorBaseUrl}/${collectorPath}/${providerSdrn(_provider)}`;
    }
    /**
     * Send all events that are in the internal event queue.
     *
     * The returned Promise will resolve with the array of events that was
     * successfully transmitted. These events will have been removed from the event
     * queue. If the promise rejects, it means that the event queue could not be
     * sent and the promise rejection will contain the events that failed (will be
     * the entire queue initial call). Events that are unsuccessfully sent will
     * remain in the event queue.
     *
     * @internal
     *
     */
    async _sendImmediately() {
        if (this.eventQueue.length + this.retryEventQueue.length > 0) {
            const promisesToBeSent = this.eventQueue.splice(0);
            const eventsToBeSent = promisesToBeSent.map((p) => p.inputValue);
            const eventsToRetry = this.retryEventQueue;
            this.eventQueue = [];
            this.retryEventQueue = [];
            // @ts-ignore @TODO cisSync must resolve to a concrete type
            const { doTracking } = await identity.cisSync({}, this.builders, this.state);
            if (!doTracking) {
                return Promise.resolve([]);
            }
            const evaluatedEvents = await Promise.all(eventsToBeSent);
            const url = this._getCollectorPath(this.state.providerId);
            return await send(url, [...evaluatedEvents, ...eventsToRetry], this.state).then((response) => {
                if (response.status >= 400) {
                    // We retry an event once, but discard already retried events
                    this.retryEventQueue = this.retryEventQueue.concat(evaluatedEvents);
                    promisesToBeSent.map((promise) => promise.reject(response));
                    return Promise.reject(evaluatedEvents);
                }
                promisesToBeSent.map((promise_1) => promise_1.resolve(response));
                return Promise.resolve(evaluatedEvents);
            }, (response_1) => {
                // We retry an event once, but discard already retried events
                this.retryEventQueue = this.retryEventQueue.concat(evaluatedEvents);
                promisesToBeSent.map((promise_2) => promise_2.reject(response_1));
                return Promise.reject(evaluatedEvents);
            });
        }
        return Promise.resolve([]);
    }
    /**
     * @internal
     * @param event
     * @param options
     */
    _push(event, options = {}) {
        const deferredEvent = defer(event);
        this.eventQueue.push(deferredEvent);
        if (options.resolveOnQueued) {
            deferredEvent.resolve(event);
        }
        return deferredEvent.promise;
    }
    /**
     * @internal
     * @param bookmarkKey
     */
    getBookmarkedEvent(bookmarkKey) {
        return this.bookmark.get(bookmarkKey);
    }
    /**
     * Send the current event queue to the back end event receiver. This event is
     * debounced, @see {SDKConfigInput} to override the default timing.
     *
     * This method is called by `track()` so it is not necessary to call after
     * adding events to the queue.
     * @category Tracker
     */
    async send() {
        if (this.state.useBeacon) {
            try {
                return await this._sendImmediately();
            }
            catch (err) {
                return err;
            }
        }
        if (!this.debouncedSend) {
            // @ts-ignore
            this.debouncedSend = debounce(() => {
                return this._sendImmediately().catch((err) => err);
            }, this.state.eventDebounce);
        }
        return this.debouncedSend();
    }
    /**
     * A convenience method which first updates the current pageViewId in the
     * tracker state and then sends a `tracker-event` with `@type: View` and the
     * merged result of the given data and the data saved in the tracker.
     *
     * Equivalent to
     *
     * ```ts
     * tracker.newPageView();
     * tracker.track('trackerEvent', { type: 'View', ...data });
     * ```
     * @category Tracker
     */
    trackPageView(data = {}, options = {}) {
        this.newPageView();
        return this.track('trackerEvent', { type: 'View', ...data }, { ...options, bookmarkKey: BookmarkKeys.PAGE_VIEW });
    }
    /**
     * Start sending events when users leave a page.
     * This will register handlers on the browser events 'pagehide', 'popstate', 'beforeunload' and 'visibilitychange'
     * and will send a Leave event when these are triggered. The leave event will be a copy of the last sent view-event
     * + engagement values. The fields in the default
     * event can be found here: https://pages.github.schibsted.io/mpt-data/pulse-standard/#leave-article.
     * The Leave event can be expanded further by passing an event-builder as the third param, but this is not recommended.
     *
     * Example usage:
     *
     * ```ts
     *  const pageElement = document.querySelector('#application') || document.body;
     *  const articleElement = document.getElementById('article-element');
     *  tracker.addPageLeaveTracking(articleElement, pageElement);
     * ```
     *
     * Passing '\{ objectResizable: true \}' as the 4th param makes the SDK re-calculate the object height whenever it changes,
     * which is useful for when the page or object changes size after loading, by ex
     * ```
     *  ...
     *  tracker.addPageLeaveTracking(articleElement, pageElement, undefined, {
     *    objectResizable: true,
     *  });
     * ```
     *
     * Keep in mind that the Beacon API is used to send events to page leave, and in order to guarantee as good as
     * possible that these events are sent, the event creator parameter should be synchronous.
     *
     * For the same reasons, we advise that the event creator parameter returns an event that does not contain any promises.
     *
     * @param {Element} objectElement The DOM element that is used to calculate various view-related fields in the default event.
     * @param {Element} pageElement The DOM element that represents the page that the user is currently on.
     * @param {LeaveEventBuilder} eventBuilder Function that returns an event to send when user leaves the page.
     * @param {LeaveEventOptions} options
     * @returns {(leaveEvent: LeaveEvent) => void} A function to update the event to send on page leave.
     * @category Leave Event
     */
    addPageLeaveTracking(objectElement, pageElement, eventBuilder, options = {}) {
        addPageLeaveTracking(this, objectElement, pageElement, eventBuilder, options);
        return updatePageLeaveEvent;
    }
    /**
     * Stop sending events when users leave a page.
     * Removes all event handlers, meaning that to event will be sent if the user leaves the page.
     * @category Leave Event
     */
    removePageLeaveTracking() {
        removePageLeaveTracking(this);
    }
    /**
     * Update leave tracking.
     * Updates the DOM element used to calculate the default fields in the Leave event.
     * @param {Element} objectElement The DOM element that is used to calculate various view-related fields in the default event.
     * @param {Element} pageElement The DOM element that represents the page that the user is currently on.
     * @param {boolean} resetProperties A flag that decided whether the properties of the default Leave event should
     * be reset when the tracking is updated. Defaults to false.
     * @category Leave Event
     */
    updatePageLeaveTracking(objectElement, pageElement, resetProperties = false) {
        updatePageLeaveTracking(objectElement, pageElement, resetProperties);
    }
    /**
     * Manually trigger a viewed content recalculation including resetting max-values
     * @param {Element} objectElement The DOM element that is used to calculate various view-related fields in the
     * default event.
     * @param {Element} pageElement The DOM element that represents the page that the user is currently on.
     * @category Leave Event
     */
    resetPageLeaveViewedContent(objectElement, pageElement) {
        resetViewedContent();
        updateViewedContent(objectElement, pageElement);
    }
    /**
     * Manually trigger a viewed content recalculation without resetting max-values
     * @param {Element} objectElement The DOM element that is used to calculate various view-related fields in the
     * default event.
     * @param {Element} pageElement The DOM element that represents the page that the user is currently on.
     * @category Leave Event
     */
    updatePageLeaveViewedContent(objectElement, pageElement) {
        updateViewedContent(objectElement, pageElement);
    }
    /**
     * Manually trigger a Leave event
     * Useful if for example the consuming website is an SPA and the normal browser events are not triggered.
     * is generated internally in the SDK.
     * @param {LeaveEvent=} eventInput
     * @param {eventName=} eventName The optional name of the browser event to simulate.
     * @category Leave Event
     */
    async trackPageLeave(eventInput, eventName) {
        await trackPageLeave(this, eventInput, eventName);
    }
    /**
     * Manually trigger a Leave event only if Page Leave tracking is active in the SDK
     * This method works similarly to
     * ```
     * trackPageLeave(event: LeaveEvent, eventName?: string)
     * ```
     * with the added guarantee that a second Leave event will not be triggered if one already has been sent.
     * is generated internally in the SDK.
     * @param {LeaveEvent=} eventInput
     * @param {eventName=} eventName The optional name of the browser event to simulate.
     * @category Leave Event
     */
    trackActivePageLeave(eventInput, eventName) {
        trackActivePageLeave(this, eventInput, eventName);
    }
}
