const emptyFn = () => {
    void 0;
};
export default function (value) {
    let res = emptyFn;
    let rej = emptyFn;
    const promise = new Promise((resolve, reject) => {
        res = resolve;
        rej = reject;
    });
    return {
        promise,
        resolve: res,
        reject: rej,
        inputValue: value,
    };
}
