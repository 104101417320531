const warned = {};
const warnOnce = (id, ...data) => {
    if (warned[id]) {
        return;
    }
    warned[id] = true;
    // eslint-disable-next-line
    console.warn(...data);
};
export default warnOnce;
