import merge from 'lodash/merge';
import { v4 as uuid } from 'uuid';
import actorBuilder, { defaultValue as actorDefaultValue } from './actor';
import consentsBuilder from './consents';
import deviceBuilder, { defaultValue as deviceDefaultValue } from './device';
import experimentMetadataBuilder, { defaultValue as experimentMetadataDefaultValue, } from './experimentMetadata';
import experimentsBuilder, { defaultValue as experimentsDefaultValue } from './experiments';
import locationBuilder, { defaultValue as locationDefaultValue } from './location';
import objectBuilder, { defaultValue as objectDefaultValue } from './objects';
import originBuilder, { defaultValue as originDefaultValue } from './origin';
import providerBuilder, { defaultValue as providerDefaultValue } from './provider';
import sessionBuilder, { defaultValue as sessionDefaultValue } from './session';
import trackerBuilder, { defaultValue as trackerDefaultValue } from './tracker';
import { dateTime } from './utils';
function routableEventDefaults(eventInput) {
    return merge({
        actor: actorDefaultValue(),
        provider: providerDefaultValue(),
    }, eventInput);
}
/**
 * Implements https://github.schibsted.io/spt-dataanalytics/event-formats/blob/master/schema/master/events/base-routable-event.json
 */
export const routableEvent = (eventInput) => {
    return Object.assign({}, eventInput, {
        '@id': uuid(),
        creationDate: dateTime(),
        schema: (eventInput || {}).schema || 'http://schema.schibsted.com/events/base-routable-event.json/235.json#',
        provider: providerBuilder(eventInput),
        actor: actorBuilder(eventInput),
    });
};
routableEvent.defaults = routableEventDefaults;
/**
 * Add default value to TrackerEventInput.
 * @todo type-bug
 */
function trackerEventInput(eventInput) {
    return merge({
        actor: actorDefaultValue(),
        experiments: experimentsDefaultValue(),
        experimentMetadata: experimentMetadataDefaultValue(),
        device: deviceDefaultValue(),
        location: locationDefaultValue(),
        object: objectDefaultValue(),
        origin: originDefaultValue(),
        provider: providerDefaultValue(),
        session: sessionDefaultValue(),
        tracker: trackerDefaultValue(),
    }, eventInput);
}
/**
 * Implements https://github.schibsted.io/spt-dataanalytics/event-formats/blob/master/schema/master/events/tracker-event.json
 */
export function trackerEvent(eventInput, sdkConfig) {
    const { type = 'View', ...props } = eventInput || {};
    const input = trackerEventInput(eventInput);
    const { pageViewId: pageViewIdInput } = eventInput || {};
    const { pageViewId: pageViewIdSdk } = sdkConfig || {};
    return Object.assign({}, props, {
        '@id': uuid(),
        '@type': type,
        pageViewId: pageViewIdInput || pageViewIdSdk,
        creationDate: dateTime(),
        schema: (eventInput || {}).schema || 'http://schema.schibsted.com/events/tracker-event.json/348.json',
        actor: actorBuilder(input),
        consents: consentsBuilder(input),
        experiments: experimentsBuilder(input),
        experimentMetadata: experimentMetadataBuilder(input),
        device: deviceBuilder(input),
        location: locationBuilder(input),
        object: objectBuilder(input),
        origin: originBuilder(input),
        provider: providerBuilder(input),
        session: sessionBuilder(input),
        target: input.target && objectBuilder(Object.assign({}, input, { object: input.target })),
        tracker: trackerBuilder(input),
    });
}
trackerEvent.defaults = trackerEventInput;
export function engagementEvent(eventInput, sdkConfig) {
    return Object.assign(trackerEvent(eventInput, sdkConfig), {
        schema: (eventInput || {}).schema || 'http://schema.schibsted.com/events/engagement-event.json/375.json',
        '@type': 'Engagement',
    });
}
engagementEvent.defaults = trackerEventInput;
export function identity(eventInput) {
    return eventInput;
}
identity.defaults = () => ({});
