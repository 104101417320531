const PROVIDER_ID_UNDEFINED = '--PROVIDER-ID-NOT-DEFINED--';
const DEFAULT_PROVIDER_TYPE = 'Organization';
export function generateProviderSDRN(providerId) {
    if (providerId) {
        return `sdrn:schibsted:client:${providerId}`;
    }
    return PROVIDER_ID_UNDEFINED;
}
export default function provider(input) {
    const { provider: providerInput } = input || {};
    const { id, type = DEFAULT_PROVIDER_TYPE, ...rest } = providerInput || {};
    const base = {
        '@type': type,
        '@id': generateProviderSDRN(id),
    };
    return Object.assign({}, base, rest);
}
export const defaultValue = () => undefined;
