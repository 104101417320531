import version from '../version.json';
/**
 * Format tracker objects
 */
export default function tracker(input) {
    const { tracker: trackerInput } = input || {};
    const base = {
        name: 'Pulse Node.js SDK',
        type: 'JS',
        version: version.SDK_VERSION,
    };
    return Object.assign({}, base, trackerInput);
}
export const defaultValue = () => undefined;
