import { parsePulseCookies } from '@spt-tracking/pulse-cis-sync';
import { device } from './device-node';
import { deviceType, isString } from './utils';
import { isBrowser } from '../WebApi';
export * from './device-node';
function supportsLocalStorage() {
    try {
        const { localStorage } = window;
        localStorage.setItem('pulse4test', 'check');
        localStorage.removeItem('pulse4test');
        return true;
    }
    catch (e) {
        return false;
    }
}
function getViewportDimensions() {
    if (!isBrowser) {
        return '';
    }
    let viewportWidth;
    let viewportHeight;
    const { document } = window;
    if (typeof window.innerWidth !== 'undefined') {
        viewportWidth = window.innerWidth;
        viewportHeight = window.innerHeight;
    }
    else if (typeof document.documentElement.clientWidth !== 'undefined' &&
        document.documentElement.clientWidth !== 0) {
        viewportWidth = document.documentElement.clientWidth;
        viewportHeight = document.documentElement.clientHeight;
    }
    else {
        viewportWidth = document.getElementsByTagName('body')[0].clientWidth;
        viewportHeight = document.getElementsByTagName('body')[0].clientHeight;
    }
    return `${viewportWidth}x${viewportHeight}`;
}
export const defaultValue = () => {
    if (!isBrowser) {
        return {};
    }
    const { navigator, screen } = window;
    return {
        acceptLanguage: navigator.userLanguage || navigator.language,
        screenSize: `${screen.width}x${screen.height}`,
        userAgent: navigator.userAgent,
        deviceType: deviceType(window.navigator.userAgent),
        viewportSize: getViewportDimensions(),
        localStorageEnabled: supportsLocalStorage(),
    };
};
export default function (input) {
    const { device: deviceInput } = input || {};
    const { environmentId, jwe } = parsePulseCookies();
    const data = {
        id: environmentId,
        jweIds: jwe,
        ...(isString(deviceInput) ? { id: deviceInput } : deviceInput),
    };
    return device(defaultValue)({ device: data });
}
