import { getActor } from './actor';
import { cisSync } from './cis';
/**
 * Get the environment id from the current browser. A promise is returned
 * because a CIS call is done to generate a new environmentId if no cookie is
 * found.
 *
 * *Note*: This method will return a Promise that is always rejected on node
 * environments.
 * @category Identifiers
 */
const getEnvironmentId = async (builders, sdkState) => {
    const actor = await getActor(builders);
    const { environmentId } = await cisSync({ actor }, builders, sdkState);
    return environmentId;
};
export { getEnvironmentId };
