import { cookieConfig, getRootDomain, readCookie, writeCookie } from '@spt-tracking/pulse-cis-sync';
import { v4 as uuid } from 'uuid';
import { isBrowser } from '../WebApi';
const { USE_LOCALSTORAGE } = cookieConfig;
const SESSION_STORAGE_KEY = '_pulsesession';
const generateSessionSDRN = (sessionId) => `sdrn:schibsted:session:${sessionId}`;
// Return parsed json from cookie (or localStorage if enabled), or undefined.
export const readAndParse = (storageKey) => {
    try {
        const storedSession = readCookie(storageKey);
        if (storedSession) {
            return JSON.parse(storedSession);
        }
    }
    catch (e) {
        // If localStorage/cookie is not available, we treat it as if
        // the value is undefined.
        // If data is unparseable, we also ignore it.
        return undefined;
    }
    return undefined;
};
// Read and parse session data from localStorage.
// Any invalid or unreadable data is ignored.
export const readSession = () => {
    const storedSession = readAndParse(SESSION_STORAGE_KEY);
    if (storedSession) {
        const [id, startTimeMs, lastEventTimeMs] = storedSession;
        const startTime = new Date(startTimeMs);
        const lastEventTime = new Date(lastEventTimeMs);
        if (id && startTime && lastEventTime && !isNaN(startTime.getTime()) && !isNaN(lastEventTime.getTime())) {
            return { id, startTime, lastEventTime };
        }
    }
    return undefined;
};
// Write session data to localStorage
const writeSession = (session) => {
    const { id, startTime, lastEventTime } = session;
    try {
        const sessionToStore = [id, startTime.getTime(), lastEventTime.getTime()];
        const halfHourInDays = 1.0 / (24 * 2);
        writeCookie(SESSION_STORAGE_KEY, JSON.stringify(sessionToStore), getRootDomain(), halfHourInDays);
        // Delete old localStorage-based session if needed
        if (!USE_LOCALSTORAGE && isBrowser && window.localStorage) {
            // removeItem will do nothing to a non-existing item.
            window.localStorage.removeItem(SESSION_STORAGE_KEY);
        }
    }
    catch (e) {
        // If we can't write the session, we just move on in life
    }
};
const createSession = () => {
    const now = new Date();
    return {
        id: generateSessionSDRN(uuid()),
        startTime: now,
        lastEventTime: now,
    };
};
const updateSession = (session) => {
    const now = new Date();
    const sessionMaxInterval = 30 * 60 * 1000; // 30 minutes in milliseconds
    if (!session || session.lastEventTime.getTime() < now.getTime() - sessionMaxInterval) {
        return createSession();
    }
    return Object.assign({}, session, { lastEventTime: now });
};
export const defaultValue = () => undefined;
// Add session information to an event
// {
//   "session": {
//     "id": "sdrn:schibsted:session:123e4567-e89b-12d3-a456-426655440000",
//     "startTime": "2020-01-21T12:29:00.999Z"
//   }
// }
export default function session() {
    const oldSession = readSession();
    const newSession = updateSession(oldSession);
    writeSession(newSession);
    const { id, startTime } = newSession;
    return {
        '@id': id,
        startTime: startTime.toISOString(),
    };
}
