import merge from 'lodash/merge';
import { isString } from './utils';
export function generateEnvironmentSDRN(id) {
    return `sdrn:schibsted:environment:${id}`;
}
function formatDeviceInput(input) {
    if (isString(input)) {
        return { id: input };
    }
    const { id, ...rest } = input || {};
    return { id, ...rest };
}
/**
 * Generate a device representation
 *
 * Several default values are used in a browser environment: acceptLanguage,
 * screenSize, userAgent, deviceType, viewportSize, localStorageEnabled. See
 * (device-browser.js)[device-browser.js] for implementation details.
 *
 * The browser implementation will also read the required values from the
 * `_pulse2cookie` and if needed sync with CIS. Note that *this is not
 * implemented for Node.js environments*. There are no default values in
 * Node.js.
 */
export const device = (defaultValueHandler) => (input) => {
    const { device: deviceInput } = input || {};
    if (!deviceInput) {
        return undefined;
    }
    const formattedInput = merge({}, defaultValueHandler(), formatDeviceInput(deviceInput));
    const { id, additionalIds, ...props } = formattedInput;
    if (!id) {
        return undefined;
    }
    const baseDevice = {
        '@type': 'Device',
        environmentId: generateEnvironmentSDRN(id),
    };
    if (additionalIds) {
        baseDevice.additionalEnvironmentIds = additionalIds.map(generateEnvironmentSDRN);
    }
    return Object.assign(baseDevice, props);
};
export const defaultValue = () => ({});
export default device(defaultValue);
