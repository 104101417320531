import { getActor } from './actor';
import { cisSync } from './cis';
export var AdvertisingVendors;
(function (AdvertisingVendors) {
    AdvertisingVendors["Xandr"] = "xandr";
    AdvertisingVendors["Delta"] = "delta";
    AdvertisingVendors["Adform"] = "adform";
})(AdvertisingVendors || (AdvertisingVendors = {}));
/**
 * Get the includeAdvertising flag, set on initialisation.
 * @private
 * @see constructor
 * @category Identifiers
 */
const getIncludeAdvertising = (sdkState) => {
    return sdkState.includeAdvertising;
};
/**
 * Get the advertisement id (adId) from the current browser. A promise is returned
 * because a CIS call is done to generate a new adId if no cookie is
 * found.
 *
 * *Note*: This method will return a Promise that is always rejected on node
 * environments.
 * @category Identifiers
 */
const getAdId = async (builders, sdkState) => {
    const { adId } = await cisSync({ actor: getActor(builders) }, builders, sdkState);
    return adId;
};
/**
 * Get the Advertisement Identifiers for a Vendor or all Vendors.
 * @param builders
 * @param sdkState
 * @param [vendors]
 * @category Identifiers
 */
const getAdvertisingIdentifiers = async (builders, sdkState, vendors) => {
    const parsedVendors = !vendors || vendors.length === 0 ? [AdvertisingVendors.Xandr] : vendors;
    const { adId, ppId = {} } = await cisSync({ actor: getActor(builders) }, builders, sdkState);
    const identifiers = {};
    parsedVendors.forEach((vendor) => {
        if (ppId[vendor]) {
            identifiers[vendor] = { ...ppId[vendor] };
            if (vendor === AdvertisingVendors.Xandr) {
                identifiers[vendor].adId = adId;
            }
        }
    });
    return identifiers;
};
export { getAdId, getIncludeAdvertising, getAdvertisingIdentifiers };
