const LEAVE_EVENT_TYPE = 'Leave';
export const DEFAULT_ACTIVITY_DURATION = () => ({
    value: 0,
    interval: undefined,
    timeSinceLastActivity: 0,
});
export const DEFAULT_VIEWED_CONTENT = () => ({
    objectViewPercentage: 0,
    maxObjectViewPercentage: 0,
    pageViewPercentage: 0,
    maxPageViewPercentage: 0,
    pageScrollPosition: 0,
    maxPageScrollPosition: 0,
});
export const DEFAULT_PAGE_LEAVE_EVENT = () => ({
    type: LEAVE_EVENT_TYPE,
    engagement: DEFAULT_VIEWED_CONTENT(),
});
export const DEFAULT_LEAVE_EVENT_BUILDER = () => () => ({});
