import { isArray, isNumber, isObject, isString } from './utils';
const objectTypeContentMapping = {
    classifiedad: 'classified',
    notificationcontent: 'notification',
    businesstransaction: 'transaction',
    uielement: 'element',
};
const getObjectContentByType = (type = '') => objectTypeContentMapping[type.toLowerCase()] ?? type.toLowerCase();
export function generateObjectSDRN(id, providerId, type) {
    if ((isString(id) || isNumber(id)) && isString(providerId) && isString(type)) {
        const contentType = getObjectContentByType(type);
        return `sdrn:${providerId}:${contentType}:${id}`;
    }
}
/**
 * Format UIElement.
 *
 * This will transform the object to a valid UIElement.
 * When building the id of the UIElement it will primarily
 * try to use 'page.id' and page.type (if available).
 * If page is not available it will fall back to use
 * 'target.id' and 'target.type' when calling sdrn().
 * If neither page nor target is available it will
 * use default type from the object.
 *
 * @private
 * @param {object} data object and provider is mandatory, rest is optional.
 */
function formatUIElement({ object, provider, page, target }) {
    const { id, type, custom, ...rest } = object || {};
    const { id: pageId, type: pageType } = page || {};
    const { id: targetId, type: targetType } = target || {};
    const { id: providerId } = provider || {};
    const uiElementId = pageType || targetType
        ? `${generateObjectSDRN(pageId || targetId, providerId, pageType || targetType) || ''}:element:${id}`
        : generateObjectSDRN(id, providerId, type);
    const baseObject = {
        '@id': uiElementId,
        '@type': type,
    };
    if (custom) {
        baseObject['spt:custom'] = custom;
    }
    return Object.assign({}, baseObject, rest);
}
function formatDefaultObject({ object, provider }) {
    const { id, type = 'Content', custom, ...rest } = object || {};
    const { id: providerId } = provider || {};
    const objectId = generateObjectSDRN(id, providerId, type);
    let baseObject = {};
    if (objectId) {
        baseObject = {
            '@id': objectId,
            '@type': type,
        };
    }
    if (custom) {
        baseObject['spt:custom'] = custom;
    }
    return Object.assign({}, baseObject, rest);
}
function formatAccount({ object }) {
    const { id, realm = 'schibsted.com', type: _, custom, ...rest } = object || {}; // type is dropped
    if (!id) {
        return;
    }
    const baseObject = {
        '@id': `sdrn:${realm}:user:${id}`,
        '@type': 'Account',
        accountId: id,
    };
    if (custom) {
        baseObject['spt:custom'] = custom;
    }
    return Object.assign({}, baseObject, rest);
}
const objectFormatterMapping = {
    UIElement: formatUIElement,
    Account: formatAccount,
};
function getObjectFormatter(type) {
    return objectFormatterMapping[type] || formatDefaultObject;
}
/**
 * Format object.
 *
 * This function will recursively format itself and all nested object.
 * The initialPage parameter is optional and will be used in the recursive
 * nature to always pass in the root object page to each formatting.
 *
 * @private
 * @param {object} input
 * @param {object|undefined} pageInput Optional
 */
export const formatObject = (input, pageInput) => {
    const { object: objectInput = {} } = input || {};
    const { page: pageInObject } = objectInput;
    const page = pageInput || pageInObject;
    const evaluatedObjectInput = Object.keys(objectInput).reduce((accl, key) => {
        const prop = objectInput[key];
        if (isArray(prop)) {
            return {
                ...accl,
                [key]: prop.map((o) => {
                    if (isObject(o)) {
                        return formatObject(Object.assign({}, input, { object: o }), page);
                    }
                    return o;
                }),
            };
        }
        else if (isObject(prop)) {
            return {
                ...accl,
                [key]: formatObject(Object.assign({}, input, { object: prop }), page),
            };
        }
        return { ...accl, [key]: prop };
    }, {});
    const output = Object.assign({}, input, { page, object: evaluatedObjectInput });
    return getObjectFormatter(objectInput.type)(output);
};
export default formatObject;
export const defaultValue = () => undefined;
