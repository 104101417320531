import merge from 'lodash/merge';
import throttle from 'lodash/throttle';
import * as logger from 'loglevel';
import { BookmarkKeys } from '../_tracker';
import { DEFAULT_ACTIVITY_DURATION, DEFAULT_LEAVE_EVENT_BUILDER, DEFAULT_PAGE_LEAVE_EVENT, DEFAULT_VIEWED_CONTENT, } from './constants';
import { BrowserEvents, } from './types';
import { isFunction } from '../builders/utils';
import { isBrowser } from '../WebApi';
let pageLeaveListeners = [];
let updateViewedContentListeners = [];
let activityListeners = [];
let resizeObserver = null;
let throttledUpdateFn = throttle(() => undefined);
let startTime = 0;
let activityDuration = DEFAULT_ACTIVITY_DURATION();
let currentPageLeaveEvent = DEFAULT_PAGE_LEAVE_EVENT();
let originalUseBeacon;
let currentLeaveEventBuilder = DEFAULT_LEAVE_EVENT_BUILDER();
let additionalHeightBuilder;
/*
 Add page leave tracking
 */
export function addPageLeaveTracking(tracker, objectElement, pageElement, eventBuilder, options = {}) {
    additionalHeightBuilder = options.additionalHeightBuilder;
    if (!leaveTrackingIsActive()) {
        originalUseBeacon = tracker.state.useBeacon;
        tracker.state.useBeacon = true;
        currentLeaveEventBuilder = eventBuilder || DEFAULT_LEAVE_EVENT_BUILDER();
        addPageLeaveListeners(tracker);
        addUpdateEventListeners(objectElement, pageElement, options.objectResizable);
        startTime = resetTime();
        logger.debug('addPageLeaveTracking:after:state', currentPageLeaveEvent);
    }
    else {
        logger.warn('addPageLeaveTracking was called while leave-tracking was already active. Consider using updatePageLeaveTracking with resetProperties=false if you want to update the page/object element!');
    }
}
function leaveTrackingIsActive() {
    return !(pageLeaveListeners.length === 0 &&
        updateViewedContentListeners.length === 0 &&
        activityListeners.length === 0);
}
/*
  Remove page leave tracking
*/
export function removePageLeaveTracking(tracker) {
    tracker.state.useBeacon = originalUseBeacon;
    removePageLeaveListeners();
    removeUpdateEventListeners();
    resetEventDefaults();
    currentLeaveEventBuilder = DEFAULT_LEAVE_EVENT_BUILDER();
    logger.debug('removePageLeaveTracking:after:state', currentLeaveEventBuilder());
}
/*
  Update page leave tracking
*/
export function updatePageLeaveTracking(objectElement, pageElement, resetProperties) {
    if (updateViewedContentListeners.length > 0) {
        removeUpdateEventListeners();
        if (resetProperties) {
            resetEventDefaults();
        }
        addUpdateEventListeners(objectElement, pageElement);
    }
}
/*
  Add event listeners to the relevant browser events
  When the browser event triggers, we want to send the Leave event
  with the event supplied by the user and the name of the triggered browser event
*/
function addPageLeaveListeners(tracker) {
    const beforeUnloadListener = addEventListeners([BrowserEvents.BEFORE_UNLOAD], () => trackPageLeave(tracker, currentLeaveEventBuilder(), BrowserEvents.BEFORE_UNLOAD));
    const pageHideListener = addEventListeners([BrowserEvents.PAGE_HIDE], () => trackPageLeave(tracker, currentLeaveEventBuilder(), BrowserEvents.PAGE_HIDE));
    const popStateListener = addEventListeners([BrowserEvents.POP_STATE], () => trackPageLeave(tracker, currentLeaveEventBuilder(), BrowserEvents.POP_STATE));
    const visibilityChangeListener = addEventListeners([BrowserEvents.VISIBILITY_CHANGE], () => {
        if (document.visibilityState === 'hidden') {
            trackPageLeave(tracker, currentLeaveEventBuilder(), BrowserEvents.VISIBILITY_CHANGE);
        }
    });
    pageLeaveListeners.push(...beforeUnloadListener);
    pageLeaveListeners.push(...pageHideListener);
    pageLeaveListeners.push(...popStateListener);
    pageLeaveListeners.push(...visibilityChangeListener);
}
/*
 Remove all Leave event listeners
 */
function removePageLeaveListeners() {
    pageLeaveListeners = removeEventListeners(pageLeaveListeners);
}
function removeResizeObserver() {
    if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver = null;
    }
}
/*
 Add handlers to update the default event.
 The fields that are included can be found here: https://pages.github.schibsted.io/mpt-data/pulse-standard/#leave-article
 */
function addUpdateEventListeners(objectElement, pageElement, objectResizable = false) {
    addUpdateViewedContentListeners(objectElement, pageElement, objectResizable);
    addUpdateActivityDurationListeners();
}
/*
 Remove all handlers for tracking viewed content and durations
 */
function removeUpdateEventListeners() {
    updateViewedContentListeners = removeEventListeners(updateViewedContentListeners);
    activityListeners = removeEventListeners(activityListeners);
    removeResizeObserver();
}
/*
 Reset the default leave tracking state.
 */
const resetEventDefaults = () => {
    currentPageLeaveEvent = DEFAULT_PAGE_LEAVE_EVENT();
    startTime = resetTime();
    activityDuration = resetActivityDuration();
};
const resetActivityDuration = () => {
    clearInterval(activityDuration.interval);
    return DEFAULT_ACTIVITY_DURATION();
};
export const resetViewedContent = () => {
    currentPageLeaveEvent.engagement = Object.assign({}, currentPageLeaveEvent.engagement, DEFAULT_VIEWED_CONTENT());
};
const resetTime = () => new Date().getTime();
/*
 Update the default Leave event with an event supplied by the brand
 */
export function updatePageLeaveEvent(leaveEvent) {
    merge(currentPageLeaveEvent, leaveEvent);
}
/*
 Send a Leave event only if Page Leave tracking is active in the SDK
 The event that is ultimately sent is a merge between the default event
 saved in this file and the event supplied by the brand, with preference going
 to the brand event. After the event has been sent, leave page tracking is removed
 and must be explicitly re-added if needed.
*/
export function trackActivePageLeave(tracker, providedEvent, eventName) {
    if (leaveTrackingIsActive()) {
        trackPageLeave(tracker, providedEvent, eventName);
    }
}
/*
 Send a Leave event regardless of whether Page Leave tracking is active in the SDK
 The event that is ultimately sent is a merge between the default event
 saved in this file and the event supplied by the brand, with preference going
 to the brand event. After the event has been sent, leave page tracking is removed
 and must be explicitly re-added if needed.
 */
export async function trackPageLeave(tracker, providedEvent, eventName) {
    const endTime = new Date().getTime();
    const duration = endTime - startTime;
    const leaveEvent = providedEvent || currentLeaveEventBuilder();
    const mergedEvent = merge({
        ...currentPageLeaveEvent,
        engagement: {
            duration,
            activityDuration: activityDuration.value,
            ...currentPageLeaveEvent.engagement,
        },
        object: {
            ...(eventName && {
                'spt:custom': {
                    'spt:eventName': eventName,
                },
            }),
        },
    }, leaveEvent);
    logger.debug('trackPageLeave: event', mergedEvent);
    tracker.track('trackerEvent', mergedEvent, { applyBookmarkKey: BookmarkKeys.PAGE_VIEW });
    removePageLeaveTracking(tracker);
}
/*
 Add listeners to update the viewed content fields in the default event
 We listen to 'scroll' and 'resize' browser events
 */
function addUpdateViewedContentListeners(objectElement, pageElement, objectResizable) {
    updateViewedContent(objectElement, pageElement);
    throttledUpdateFn = throttle(() => updateViewedContent(objectElement, pageElement), 500, { leading: true });
    updateViewedContentListeners = addEventListeners([BrowserEvents.SCROLL, BrowserEvents.RESIZE], throttledUpdateFn);
    if (objectResizable) {
        if (!resizeObserver) {
            resizeObserver = new ResizeObserver(() => {
                resetViewedContent();
                throttledUpdateFn();
            });
        }
        resizeObserver.observe(objectElement);
    }
}
/*
 Update the viewed content fields that are used in the default event.
 The includes fields are:
 {pageScrollPosition} The absolute scroll position on the page in pixels.
 {maxPageScrollPosition} The maximum scroll position the user has been on in pixels.
 {objectViewPercentage} The relative scroll position of the supplied DOM element in pixels.
 {maxObjectViewPercentage} The maximum relative scroll position of the supplied DOM element in pixels.
 {pageViewPercentage} The relative scroll position of the user in %.
  {maxPageViewPercentage} The maximum relative scroll position the user has seen in %.
*/
export function updateViewedContent(objectElement, pageElement) {
    const { totalHeight: additionalTotalHeight, viewedHeight: additionalViewedHeight } = isFunction(additionalHeightBuilder)
        ? additionalHeightBuilder()
        : { totalHeight: 0, viewedHeight: 0 };
    const objectScrolledPixels = calculateScrolledPixels(objectElement) + additionalViewedHeight;
    const pageScrolledPixels = calculateScrolledPixels(pageElement) + additionalViewedHeight;
    const pageScrollPosition = calculateScrollPosition(pageElement) + additionalViewedHeight;
    const maxPageScrollPosition = Math.max(pageScrollPosition, Number(currentPageLeaveEvent.engagement.maxPageScrollPosition));
    const objectViewPercentage = calculateViewPercentage(objectScrolledPixels, objectElement.scrollHeight + additionalTotalHeight);
    const maxObjectViewPercentage = Math.max(objectViewPercentage, Number(currentPageLeaveEvent.engagement.maxObjectViewPercentage));
    const pageViewPercentage = calculateViewPercentage(pageScrolledPixels, pageElement.scrollHeight + additionalTotalHeight);
    const maxPageViewPercentage = Math.max(pageViewPercentage, Number(currentPageLeaveEvent.engagement.maxPageViewPercentage));
    logger.debug('updateViewedContent:before:engagement', { ...currentPageLeaveEvent.engagement });
    merge(currentPageLeaveEvent.engagement, {
        pageScrollPosition,
        maxPageScrollPosition,
        objectViewPercentage,
        maxObjectViewPercentage,
        pageViewPercentage,
        maxPageViewPercentage,
    });
    logger.debug('updateViewedContent:after:engagement', { ...currentPageLeaveEvent.engagement });
}
export const calculateScrolledPixels = (element) => {
    const rect = element.getBoundingClientRect();
    const scrolledPixels = isBrowser ? element.scrollHeight - rect.bottom + window.innerHeight : 0;
    if (scrolledPixels <= 0) {
        return 0;
    }
    return Math.min(scrolledPixels, element.scrollHeight);
};
export const calculateScrollPosition = (element) => Math.abs(Math.min(element.getBoundingClientRect().top, 0));
/*
 Track the user's activity duration on the page.
 The activity duration is the active time the user spends on the page.
 Active means less than 30 seconds since the user triggered one of the
 'mousedown', 'mousemove', 'keydown', 'scroll' and/or 'touchstart' browser events.
 */
function addUpdateActivityDurationListeners() {
    const interval = 1000;
    const activityTimeout = 30 * 1000;
    activityDuration.interval = window.setInterval(() => {
        activityDuration.timeSinceLastActivity += interval;
        if (activityDuration.timeSinceLastActivity < activityTimeout) {
            activityDuration.value += interval;
        }
    }, interval);
    const activityEvents = [
        BrowserEvents.SCROLL,
        BrowserEvents.MOUSEDOWN,
        BrowserEvents.MOUSEMOVE,
        BrowserEvents.KEYDOWN,
        BrowserEvents.TOUCHSTART,
    ];
    const passiveSupported = isPassiveSupported();
    activityListeners = addEventListeners(activityEvents, () => {
        activityDuration = resetLastActivity(activityDuration);
    }, passiveSupported ? { passive: true } : false);
}
function resetLastActivity(activityDuration) {
    return {
        ...activityDuration,
        timeSinceLastActivity: 0,
    };
}
/*
 Add listeners to a set of browser events.
 */
export function addEventListeners(events, handler, options) {
    return events.map((eventName) => {
        if (isBrowser) {
            window.addEventListener(eventName, handler, options);
        }
        return [eventName, handler];
    });
}
/*
 Remove a set of listeners for browser events.
 */
export function removeEventListeners(eventListeners) {
    eventListeners.forEach(([eventName, listener]) => {
        if (isBrowser) {
            window.removeEventListener(eventName, listener);
        }
    });
    throttledUpdateFn.cancel();
    return [];
}
const calculateViewPercentage = (scrolledPixels, scrollHeight) => Math.round((100 * scrolledPixels) / scrollHeight);
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#safely_detecting_option_support
const isPassiveSupported = () => {
    let passiveSupported;
    if (passiveSupported !== undefined) {
        return passiveSupported;
    }
    try {
        const options = {
            get passive() {
                passiveSupported = true;
                return false;
            },
        };
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const noop = () => { };
        window.addEventListener('test', noop, options);
        window.removeEventListener('test', noop);
    }
    catch (err) {
        passiveSupported = false;
    }
    return passiveSupported;
};
