import { clearCookieAdvertisingIds } from '@spt-tracking/pulse-cis-sync';
import { isObject, isString } from './utils';
import warnOnce from '../warnOnce';
/**
 * @enum ConsentCategory
 */
export var ConsentsCategory;
(function (ConsentsCategory) {
    ConsentsCategory["CMP_ANALYTICS"] = "CMP_ANALYTICS";
    ConsentsCategory["CMP_MARKETING"] = "CMP_MARKETING";
    ConsentsCategory["CMP_ADVERTISING"] = "CMP_ADVERTISING";
    ConsentsCategory["CMP_PERSONALIZATION"] = "CMP_PERSONALIZATION";
})(ConsentsCategory || (ConsentsCategory = {}));
/**
 * @enum ConsentsSource
 */
export var ConsentsSource;
(function (ConsentsSource) {
    ConsentsSource["DEFAULT"] = "default";
    ConsentsSource["CMP"] = "cmp";
    ConsentsSource["CACHE"] = "cache";
})(ConsentsSource || (ConsentsSource = {}));
/**
 * @enum ConsentsPurposeCategoryStatus
 */
export var ConsentsPurposeCategoryStatus;
(function (ConsentsPurposeCategoryStatus) {
    ConsentsPurposeCategoryStatus["ACCEPTED"] = "accepted";
    ConsentsPurposeCategoryStatus["REJECTED"] = "rejected";
    ConsentsPurposeCategoryStatus["UNKNOWN"] = "unknown";
})(ConsentsPurposeCategoryStatus || (ConsentsPurposeCategoryStatus = {}));
const isLegacyConsentsPurpose = (consentsPurposeCategory) => {
    return !consentsPurposeCategory?.status;
};
const isLegacyConsents = (consents) => {
    return !!(consents?.purposes && Object.values(consents.purposes).every((purpose) => isLegacyConsentsPurpose(purpose)));
};
const NO_CONSENT_IN_CONFIG = 'Invalid consents passed to the Pulse SDK. "requireAdvertisingOptIn" and no "consents" config param present.';
/**
 *
 * @param consents
 * @param requireAdvertisingOptIn
 * @returns {Object} The resolved consents and whether advertising is allowed
 */
export function applyAdvertisingConsent(consents, requireAdvertisingOptIn) {
    if (!consents && typeof requireAdvertisingOptIn !== 'undefined' && requireAdvertisingOptIn !== undefined) {
        warnOnce('NO_CONSENT_IN_CONFIG', NO_CONSENT_IN_CONFIG);
    }
    const includeAdvertising = resolveIncludeAdvertising(consents, requireAdvertisingOptIn);
    if (!includeAdvertising) {
        clearCookieAdvertisingIds();
    }
    return includeAdvertising;
}
/**
 *
 * @param consents
 * @param requireAdvertisingOptIn
 * @returns {Boolean} whether advertising is allowed
 */
export function resolveIncludeAdvertising(consents, requireAdvertisingOptIn) {
    if (!requireAdvertisingOptIn) {
        return true;
    }
    if (isLegacyConsents(consents)) {
        return Boolean(consents?.purposes?.[ConsentsCategory.CMP_ADVERTISING]?.optIn);
    }
    return consents?.purposes?.[ConsentsCategory.CMP_ADVERTISING]?.status === ConsentsPurposeCategoryStatus.ACCEPTED;
}
export default function consents(input) {
    const { consents: consentsInput } = input || {};
    if (!consentsInput) {
        return undefined;
    }
    const purposesInput = consentsInput?.purposes ?? {};
    return {
        purposes: purposes(purposesInput),
        source: consentsInput?.source,
    };
}
/**
 * @deprecated
 * @internal
 * We MUST honour the Defaults passed by CMP, and not define one internally.
 * Still present for Denmark compatibility.
 */
export const defaultPurposes = {
    [ConsentsCategory.CMP_ADVERTISING]: {
        optIn: false,
        status: ConsentsPurposeCategoryStatus.UNKNOWN,
    },
    [ConsentsCategory.CMP_ANALYTICS]: {
        optIn: false,
        status: ConsentsPurposeCategoryStatus.UNKNOWN,
    },
    [ConsentsCategory.CMP_MARKETING]: {
        optIn: false,
        status: ConsentsPurposeCategoryStatus.UNKNOWN,
    },
    [ConsentsCategory.CMP_PERSONALIZATION]: {
        optIn: false,
        status: ConsentsPurposeCategoryStatus.UNKNOWN,
    },
};
/**
 * Maps a Consent.Purpose.Status to the equivalent OptIn fallback property value
 */
const purposeStatusToOptIn = {
    [ConsentsPurposeCategoryStatus.ACCEPTED]: true,
    [ConsentsPurposeCategoryStatus.REJECTED]: false,
    [ConsentsPurposeCategoryStatus.UNKNOWN]: false,
};
/**
 * @deprecated
 * @internal
 * We should not try to alter the consent input intended by the outer app scope.
 * @param purposesInput
 */
function purposes(purposesInput) {
    const purposesOutput = { ...defaultPurposes };
    for (const categoryInput in purposesInput) {
        const consentCategory = categoryInput;
        const isValidCategory = Boolean(defaultPurposes[consentCategory]);
        if (isValidCategory) {
            const purposeInput = purposesInput[consentCategory];
            if (isString(purposeInput) && validatePurposeId(purposeInput)) {
                purposesOutput[consentCategory] = toConsentPurposeCategory(purposesInput[consentCategory]);
            }
            else if (isObject(purposeInput)) {
                if (purposeInput.status && purposeInput.optIn === undefined) {
                    purposeInput.optIn = purposeStatusToOptIn[purposeInput.status];
                }
                purposesOutput[consentCategory] = purposeInput;
            }
        }
    }
    return purposesOutput;
}
/**
 * @deprecated
 * @see purposes
 * @param purposeId
 */
function validatePurposeId(purposeId) {
    return (purposeId && purposeId !== '');
}
/**
 * @deprecated
 * @see purposes
 * @param purposeId
 */
function toConsentPurposeCategory(purposeId) {
    return {
        '@id': purposeId,
        optIn: true,
    };
}
