import { isBrowser } from '../WebApi';
export * from './objects-node';
export { default } from './objects-node';
export const defaultValue = () => {
    if (!isBrowser) {
        return {};
    }
    const { location = { href: undefined } } = window;
    return {
        name: document.title,
        url: location.href,
        id: location.href,
    };
};
