import { isBrowser } from './WebApi';
const supportsBeacon = isBrowser && Boolean(window.navigator.sendBeacon);
export default function send(url, data, sdkConfig) {
    // if there is as large number of events, only send 10 in each request
    /* eslint no-param-reassign: 0 */
    const eventChunks = Array.isArray(data)
        ? data.reduce((acc, event) => {
            if (acc[0].length >= 10) {
                acc = [[event]].concat(acc);
            }
            else {
                acc[0].push(event);
            }
            return acc;
        }, [[]])
        : [[data]];
    if (isBrowser && supportsBeacon && (sdkConfig.useBeaconWhenAvailable || sdkConfig.useBeacon)) {
        const result = eventChunks.reduce((previousStatus, eventChunk) => previousStatus && window.navigator.sendBeacon(url, JSON.stringify(eventChunk)), true);
        if (result) {
            return Promise.resolve(true);
        }
        return Promise.reject(false);
    }
    const requests = eventChunks.map((eventChunk) => fetch(url, {
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
        method: 'POST',
        body: JSON.stringify(eventChunk),
    }));
    return Promise.all(requests);
}
