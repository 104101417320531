/**
 * @typedef BrowserEvents
 */
export var BrowserEvents;
(function (BrowserEvents) {
    BrowserEvents["BEFORE_UNLOAD"] = "beforeunload";
    BrowserEvents["PAGE_HIDE"] = "pagehide";
    BrowserEvents["POP_STATE"] = "popstate";
    BrowserEvents["VISIBILITY_CHANGE"] = "visibilitychange";
    BrowserEvents["SCROLL"] = "scroll";
    BrowserEvents["RESIZE"] = "resize";
    BrowserEvents["MOUSEDOWN"] = "mousedown";
    BrowserEvents["MOUSEMOVE"] = "mousemove";
    BrowserEvents["KEYDOWN"] = "keydown";
    BrowserEvents["TOUCHSTART"] = "touchstart";
})(BrowserEvents || (BrowserEvents = {}));
